import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map, Observable, of, switchMap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "projects/aqua3/src/environments/environment";
import { IRolePrivilegesData } from "../../interfaces/administration/rolePrivilegesData";
import { LoggedUserService } from "../common/logged-user.service";
import { ILoggedUserData } from "../../interfaces/common/loggedUserData";
const apiUrl = environment.apiUrl + "/role-privileges";

interface IRolePrivilegesCache {
    date: Date;
    rolePrivileges: IRolePrivilegesData[];
}

@Injectable({
    providedIn: "root",
})
export class RolePrivilegesDataService {
    private cacheDuration = 5 * 60 * 1000; // 5 minutes
    private roleCache: IRolePrivilegesCache = {
        date: new Date(),
        rolePrivileges: [],
    };

    constructor(private http: HttpClient, private loggedUserService: LoggedUserService, private router: Router) { }

    public getRolePrivileges(dbRoleName: string): Observable<IRolePrivilegesData[]> {
        return this.http.get<IRolePrivilegesData[]>(
            `${apiUrl}/${dbRoleName}`
        );
    }

    public updateRolePrivileges(rolePrivilegesData: IRolePrivilegesData[]) {
        return this.http.put(`${apiUrl}/update`, rolePrivilegesData);
    }

    public getAllRolePrivileges(): Observable<IRolePrivilegesData[]> {
        const cachedRolePrivileges = this.roleCache.rolePrivileges;
        const now = new Date();
        if (cachedRolePrivileges && cachedRolePrivileges.length && (now.getTime() - new Date(this.roleCache.date).getTime()) < this.cacheDuration) {
            return of(cachedRolePrivileges);
        } else {
            return this.loggedUserService.getLoggedUserData().pipe(
                switchMap((loggedUser: ILoggedUserData | null) => {
                    if (loggedUser) {
                        return this.getMergedPrivileges(loggedUser.email).pipe(
                            map(data => {
                                this.roleCache = {
                                    date: now,
                                    rolePrivileges: data,
                                };
                                return data;
                            })
                        );
                    } else {
                        console.error("Cannot fetch logged user data in add-agency form.");
                        return of([] as IRolePrivilegesData[]);
                    }
                })
            );
        }
    }

    public getSpecificRolePrivilege(pageName: string, subPageName: string): Observable<IRolePrivilegesData> {
        const cachedRolePrivileges = this.roleCache.rolePrivileges.find(x => x.pageCategory == pageName
            && x.pageSubCategory == subPageName);
        const now = new Date();
        if (cachedRolePrivileges && (now.getTime() - new Date(this.roleCache.date).getTime()) < this.cacheDuration) {
            return of(cachedRolePrivileges);
        } else {
            return this.loggedUserService.getLoggedUserData().pipe(
                switchMap((loggedUser: ILoggedUserData | null) => {
                    if (loggedUser) {
                        return this.getMergedPrivileges(loggedUser.email).pipe(
                            map(data => {
                                if (!data || !data.length) {
                                    this.router.navigate(['no-access']);
                                    return {
                                        pageCategory: pageName,
                                        pageSubCategory: subPageName,
                                        accessType: "No Access",
                                    } as IRolePrivilegesData;
                                } else {
                                    this.roleCache = {
                                        date: now,
                                        rolePrivileges: data,
                                    };
                                    let result = data.find(x => x.pageCategory == pageName && x.pageSubCategory == subPageName);
                                    return result;
                                }
                            })
                        );
                    } else {
                        console.error("Cannot fetch logged user data in add-agency form.");
                        return of({} as IRolePrivilegesData);
                    }
                })
            );
        }
    }

    private getMergedPrivileges(userEmail: string): Observable<IRolePrivilegesData[]> {
        const encodedUserEmail = encodeURIComponent(userEmail);
        return this.http.get<IRolePrivilegesData[]>(
            `${apiUrl}/merged/${encodedUserEmail}`
        );
    }
}