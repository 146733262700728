import { HttpClient } from "@angular/common/http";
import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Output,
} from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { EventEmitter } from "@angular/core";
import { ICapitalProjectData } from "../../interfaces/administration/capitalProjectData";
import { ICommodity } from "../../interfaces/administration/commodity";
import { ILookupData } from "../../interfaces/administration/lookupData";
import { PerformanceTrackingSearchFilter } from "../../interfaces/administration/PerformanceTrackingSearchFilter";
import { IViewPerformanceTrackingData } from "../../interfaces/vendor-information/viewPerformanceTrackingData";
import { CommodityCodesService } from "../../services/administration/commodity-codes.service";
import { PurchaserManagementService } from "../../services/administration/purchaser-management.service";
import { CapitalProjectsDataService } from "../../services/capital-projects.service";
import { UserPermissionsService } from "../../services/common/user-permissions.service";
import { ManufacturersNameService } from "../../services/qa-reports/manufacturers-name.service";
import { PerformanceTrackingService } from "../../services/vendor-information/performance-tracking.service";
import { BaseComponent } from "../../shared/base/base.component";

@Component({
  selector: "app-performance-tracking",
  templateUrl: "./performance-tracking.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }

      .unchecked {
        color: red;
      }

      .mt-1 {
        margin-top: 1rem !important;
      }
    `,
  ],
})
export class PerformanceTrackingComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  title = "Performance Tracking";
  dataSource: MatTableDataSource<IViewPerformanceTrackingData> =
    new MatTableDataSource<IViewPerformanceTrackingData>();
  originalData: MatTableDataSource<IViewPerformanceTrackingData> =
    new MatTableDataSource<IViewPerformanceTrackingData>();
  searchForm: FormGroup;
  commodityCodes: number[] = [];
  selectedCommodityObjects: ICommodity[] = [];
  commoditiesData: ICommodity[] = [];
  totalData: number = 0;

  @ViewChild("paginator") paginator: MatPaginator;
  @Output() clearCommodityCodesEvent = new EventEmitter<void>();

  displayedColumns: string[] = [
    "actions",
    "projectLongName",
    "commodityCodeTrimmed",
    "commodityDescriptionPath",
    "poNumber",
    "eng",
    "pro",
    "mfg",
    "con",
  ];

  dataSourceLoading: boolean = true;
  capitalProjectsData: ICapitalProjectData[] = [];
  purchasers: ILookupData[] = [];
  projectLocations: string[] = [];

  get isFilterSelected(): boolean {
    const filters = this.searchForm.value;
    return Object.values(filters).some((value) => value && value !== "");
  }

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private service: PerformanceTrackingService,
    userPermissionsService: UserPermissionsService,
    private capitalProjectsService: CapitalProjectsDataService,
    private purchaserService: PurchaserManagementService,
    private manufacturerService: ManufacturersNameService,
    private commodityCodesService: CommodityCodesService
  ) {
    super(userPermissionsService);
    this.searchForm = this.fb.group({
      recordDateFrom: [""],
      recordDateTo: [""],
      projectName: [""],
      projectLocation: [""],
      purchaser: [""],
      poNumber: [""],
      vendor: [""],
      commodityDescription: [""],
      commodityCodes: [[] as number[]],
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadCapitalProjects();
    this.getPurchasers();
    this.loadProjctsLocation();
    this.updateListings();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    if (this.paginator) {
      this.paginator.page.subscribe((event: PageEvent) => {
        this.updateListings(event.pageIndex + 1, event.pageSize);
      });
    } else {
      console.error("Paginator is not initialized");
    }
  }

  updateListings(pageIndex: number = 1, pageSize: number = 10) {
    this.dataSourceLoading = true;

    const formValues = this.searchForm.value;

    const recordDateFromString = formValues.recordDateFrom
      ? new Date(formValues.recordDateFrom).toISOString()
      : "";
    const recordDateToString = formValues.recordDateTo
      ? new Date(formValues.recordDateTo).toISOString()
      : "";

    const params = {
      recordDateFrom: recordDateFromString,
      recordDateTo: recordDateToString,
      projectName: formValues.projectName,
      projectLocation: formValues.projectLocation,
      purchaser: formValues.purchaser,
      poNumber: formValues.poNumber,
      vendor: formValues.vendor,
      commodityDescription: formValues.commodityDescription,
      commodityCodes: formValues.commodityCodes.join(","),
      pageIndex: pageIndex,
      pageSize: pageSize,
    };

    this.service
      .getPerformanceTrackingData(
        params.projectName,
        params.projectLocation,
        params.purchaser,
        params.vendor,
        params.commodityDescription,
        params.poNumber,
        params.commodityCodes,
        params.recordDateFrom,
        params.recordDateTo,
        params.pageIndex,
        params.pageSize
      )
      .subscribe({
        next: (data) => {
          this.dataSourceLoading = true;

          this.dataSource =
            new MatTableDataSource<IViewPerformanceTrackingData>(data.items);

          this.originalData =
            new MatTableDataSource<IViewPerformanceTrackingData>(data.items);

          setTimeout(() => {
            this.paginator.pageSize = pageSize;
            this.totalData = data.totalCount;
            this.paginator.pageIndex = pageIndex - 1;
          });
          this.dataSourceLoading = false;
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  handleSelectedCommodityNames(selectedCommodityNames: ICommodity[]): void {
    this.selectedCommodityObjects = selectedCommodityNames;
    if (selectedCommodityNames.length !== 0) {
      this.searchForm
        .get("commodityCodes")
        .setValue(
          selectedCommodityNames.map((commodity) => commodity.commodityId)
        );
    } else {
      this.searchForm.get("commodityCodes").setValue([]);
    }
  }

  loadCommodities(): void {
    this.commodityCodesService
      .getAllCommodities()
      .subscribe((data: ICommodity[]) => {
        this.commoditiesData = data;
      });
  }

  onSearch() {
    this.applyFilters();
  }

  loadProjctsLocation() {
    this.capitalProjectsService.getProjectsLocations().subscribe((data) => {
      this.projectLocations = data;
    });
  }

  loadCapitalProjects() {
    this.capitalProjectsService.getAllProjects().subscribe((data) => {
      this.capitalProjectsData = data;
    });
  }

  getPurchasers() {
    this.purchaserService.getPurchasers().subscribe((data: ILookupData[]) => {
      this.purchasers = data;
    });
  }

  applyFilters() {
    // Reset paginator to first page
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.updateListings();
  }

  clearFilters() {
    this.searchForm.reset();
    this.selectedCommodityObjects = [];
    this.clearCommodityCodesEvent.emit();

    // Reset paginator to first page
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }

    this.updateListings();
  }

  select(data: IViewPerformanceTrackingData) {
    this.router.navigate([
      "vendor-information/performance-tracking/preview-spt/",
      data.performanceTrackingId,
    ]);
  }

  moveToCreatePerformance() {
    this.router.navigate(["/approved-vendor-list"]);
  }

  exportData() {
    const filters = this.searchForm.value;

    const filter: PerformanceTrackingSearchFilter = {
      projectId: filters.projectName ? Number(filters.projectName) : 0,
      projectLocation: filters.projectLocation || "",
      epcId: filters.purchaser ? Number(filters.purchaser) : 0,
      vendor: filters.vendor || "",
      commodityId:
        filters.commodityCodes.length > 0 ? filters.commodityCodes : [0],
      commodityDescription: filters.commodityDescription || "",
      poNumber: filters.poNumber || "",
      fromDate: filters.recordDateFrom
        ? new Date(filters.recordDateFrom)
        : undefined,
      toDate: filters.recordDateTo ? new Date(filters.recordDateTo) : undefined,
    };

    this.manufacturerService.getsptreport(filter).subscribe((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `AQUA3_SupplierPerformaceTrackingReport_${new Date()
        .toISOString()
        .slice(0, 19)
        .replace(/:/g, "-")}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }
}
