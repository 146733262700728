import { Component, EventEmitter, Injectable, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ICommodity } from '../../../interfaces/administration/commodity';
import { CommodityCodesService } from '../../../services/administration/commodity-codes.service';
import { CollectionViewer, SelectionChange } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { BehaviorSubject, map, merge, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DynamicDatabase {
    constructor(private commodityCodesService: CommodityCodesService) { }

    initialData(): Observable<ICommodity[]> {
        var mainNode: ICommodity;
        return this.commodityCodesService.getAllChildrenAPI(0).pipe(map((children) => {
            mainNode = {
                commodityId: 0,
                childrenCount: 0,
                description: 'AQUA2 Commodity Codes',
                commodityDescriptionPath: '',
                commodityCodeParentId: 0,
                commodityLevel: 0,
                commodityCodeLevel1: 0,
                commodityCodeLevel2: 0,
                commodityCodeLevel3: 0,
                commodityCodeLevel4: 0,
                commodityCodeTrimmed: '',
                isActive: true,
                isApproved: true,
                subjectMatterExpertUserId: 0,
                approvedByUserId: 0,
                approvedDate: '',
                approvedComments: '',
                approverFirstName: '',
                approverLastName: '',
                approverEmail: '',
                approverCai: '',
                smefirstName: '',
                smelastName: '',
                smeemail: '',
                smecai: '',
                children: children,
                shouldExpand: true,
                searched: false,
                isLoading: false,
                isExpandable: children.length > 0,
                commodityPath: '',
            };
            return [mainNode];
        }));
    }
    async getChildren(node: ICommodity): Promise<ICommodity[]> {
        const children = await this.commodityCodesService.getAllChildrenAPI(node.commodityId).toPromise();
        return children;
    }

    isExpandable(node: ICommodity): boolean {
        return node.isExpandable;
    }
}

@Injectable()
export class DynamicDataSource {

    dataChange: BehaviorSubject<ICommodity[]> = new BehaviorSubject<ICommodity[]>([]);

    private childrenSubject = new BehaviorSubject<ICommodity[]>([]);
    children$: Observable<ICommodity[]> = this.childrenSubject.asObservable();

    get data(): ICommodity[] { return this.dataChange.value; }
    set data(value: ICommodity[]) {
        this.treeControl.dataNodes = value;
        this.dataChange.next(value);
    }

    constructor(private treeControl: FlatTreeControl<ICommodity>,
        private database: DynamicDatabase) { }

    connect(collectionViewer: CollectionViewer): Observable<ICommodity[]> {
        this.treeControl.expansionModel.changed!.subscribe(change => {
            if ((change as SelectionChange<ICommodity>).added ||
                (change as SelectionChange<ICommodity>).removed) {
                this.handleTreeControl(change as SelectionChange<ICommodity>);
            }
        });

        return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
    }

    /** Handle expand/collapse behaviors */
    handleTreeControl(change: SelectionChange<ICommodity>) {
        if (change.added) {
            change.added.forEach((node) => this.toggleNode(node, true));
        }
        if (change.removed) {
            change.removed.reverse().forEach((node) => this.toggleNode(node, false));
        }
    }

    async fetchChildren(node: ICommodity): Promise<ICommodity[]> {
        const children = await this.database.getChildren(node);
        this.childrenSubject.next(children);
        return children;
    }

    async toggleNode(node: ICommodity, expand: boolean) {
        node.isLoading = true;
        const children = await this.fetchChildren(node);
        const index = this.data.indexOf(node);
        if (!children || index < 0) { // If no children, or cannot find the node, no op
            return;
        }

        if (expand) {
            const nodes = children.map(commodity => commodity);
            this.data.splice(index + 1, 0, ...nodes);
            this.dataChange.next(this.data);
        } else {
            this.removeDescendants(node);
            this.dataChange.next(this.data);
        }
        node.isLoading = false;
    }

    /**
     * Remove all descendants of the node from the data list
     */
    private removeDescendants(node: ICommodity) {
        const index = this.data.indexOf(node);
        if (index < 0) {
            return;
        }

        let count = 0;
        for (let i = index + 1; i < this.data.length && this.data[i].commodityLevel > node.commodityLevel; i++, count++) { }

        this.data.splice(index + 1, count);
    }
}

@Component({
    selector: 'commodity-codes-selectable-tree',
    templateUrl: './commodity-codes-selectable-tree.component.html',
    styleUrls: ["./commodity-codes-selectable-tree.component.css"],
})
export class CommodityCodesSelectableTreeComponent {
    @Output() selectedCommodityIdsChange = new EventEmitter<ICommodity[]>();
    @Input() onlyLastChildCheckboxMode: boolean = true;
    @Input() clearSelectedCheckboxes: EventEmitter<void>;
    public commoditiesData!: MatTableDataSource<ICommodity>;
    public commoditiesIsLoading: boolean = true;
    isLoaded = false;
    treeControl: FlatTreeControl<ICommodity>;
    dataSource: DynamicDataSource;
    commodityForm: FormGroup;

    constructor(private commodityCodesService: CommodityCodesService, private fb: FormBuilder, database: DynamicDatabase) {
        this.treeControl = new FlatTreeControl<ICommodity>(this.getLevel, this.isExpandable);
        this.dataSource = new DynamicDataSource(this.treeControl, database);

        database.initialData().subscribe((data) => {
            this.dataSource.data = data;
            this.isLoaded = true;
        });
    }

    ngOnInit() {
        this.commodityForm = this.fb.group({
            commodities: this.fb.array(this.dataSource.data.map(() => this.fb.control(false)))
        });

        this.commodityForm.valueChanges.subscribe(() => {
            this.onSubmit();
        });

        this.dataSource.children$.subscribe(children => {
            this.addCheckboxes(children);
        });

        this.clearSelectedCheckboxes?.subscribe(() => {
            this.clearCommodityForm();
        });
    }

    addCheckboxes(children: ICommodity[]) {
        children.forEach(childCommodity => {
            this.commodityForm.addControl(childCommodity.commodityId.toString(), this.fb.control(false));
        });
    }

    get commoditiesFormArray() {
        return this.commodityForm.get('commodities') as FormArray;
    }

    onNodeClick() {
        const selectedCommodityNames = Object.keys(this.commodityForm.value)
            .filter(key => this.commodityForm.value[key] === true);

        const selectedCommodityObjects: ICommodity[] = this.dataSource.data.filter(commodity => selectedCommodityNames.includes(commodity.commodityId.toString()));
        this.selectedCommodityIdsChange.emit(selectedCommodityObjects);
    }

    onSubmit() {
    }

    getLevel = (node: ICommodity) => {
        if (node != null) {
            return node.commodityLevel;
        } else {
            return 0;
        }
    };

    isExpandable = (node: ICommodity) => {
        return node.isExpandable;
    };

    hasChild = (_: number, _nodeData: ICommodity) => {
        if (_nodeData != null) {
            return _nodeData.isExpandable;
        } else {
            return false;
        }
    }

    getChildren(node: ICommodity) {
        return this.commodityCodesService.getChildren(node.commodityId);
    }

    clearCommodityForm() {
        this.commodityForm.reset({
            commodities: this.dataSource.data.map(() => false)
        });
    }
}