import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoggedUserService } from "./common/logged-user.service";
import { BehaviorSubject, catchError, Observable, throwError } from "rxjs";
import { IAq2QaqcbuyerEngineerQae } from "../interfaces/qa-management/aq2QaqcbuyerEngineerQae";
import { environment } from "../../environments/environment";
import { FormGroup } from "@angular/forms";
import { IBuyerEngineerQaeDetailViewData } from "../interfaces/qa-management/buyerEngineerQaeDetailViewData";
import { PaginatedResponse } from "./qa-management/work-requests/work-requests.service";
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class QaqcbuyerEngineerQaeService {
  constructor(
    private http: HttpClient,
    private loggedUserService: LoggedUserService
  ) {}

  private currentBuyerEngineerQAEs: BehaviorSubject<IAq2QaqcbuyerEngineerQae> =
    new BehaviorSubject(null);
  currentBuyerEngineerQAEs$: Observable<IAq2QaqcbuyerEngineerQae> =
    this.currentBuyerEngineerQAEs.asObservable();

  public getQaqcbuyerEngineerQae(
    name: string,
    email: string,
    role: string,
    PageIndex: number = 1,
    PageSize: number = 10
  ): Observable<PaginatedResponse<IAq2QaqcbuyerEngineerQae>> {
    let params = new HttpParams();
    params = params.append("name", name);
    params = params.append("email", email);
    params = params.append("role", role);
    params = params.append("PageIndex", PageIndex.toString());
    params = params.append("PageSize", PageSize.toString());
    return this.http.get<PaginatedResponse<IAq2QaqcbuyerEngineerQae>>(
      `${apiUrl}/qaqcbuyerengineerqae`,
      {
        params,
      }
    );
  }

  public getQaqcbuyerEngineerQaeById(
    id: number
  ): Observable<IAq2QaqcbuyerEngineerQae> {
    return this.http.get<IAq2QaqcbuyerEngineerQae>(
      `${apiUrl}/qaqcbuyerengineerqae/${id}`
    );
  }

  public saveQaqcbuyerEngineerQae(
    newBuyer: IAq2QaqcbuyerEngineerQae
  ): Observable<any> {
    return this.http
      .post<IAq2QaqcbuyerEngineerQae>(
        `${apiUrl}/qaqcbuyerengineerqae`,
        newBuyer
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    let errorMsgs: string[] = [];
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred.
      errorMsgs.push(`An error occurred: ${error.error.message}`);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain the validation errors.
      if (error.status === 400 && error.error instanceof Array) {
        // Extract the error messages from the error objects
        errorMsgs = error.error.map((err) => err.errorMessage);
      } else {
        errorMsgs.push(`Server returned code: ${error.status}`);
      }
    }
    // Return an observable with the error messages.
    return throwError(() => new Error(errorMsgs.join(",")));
  }

  public updateQaqcbuyerEngineerQae(
    buyer: IAq2QaqcbuyerEngineerQae
  ): Observable<any> {
    return this.http.put<IAq2QaqcbuyerEngineerQae>(
      `${apiUrl}/qaqcbuyerengineerqae/${buyer.id}`,
      buyer
    );
  }

  public setCurrentAq2QaqcbuyerEngineerQae(data: IAq2QaqcbuyerEngineerQae) {
    this.currentBuyerEngineerQAEs.next(data);
  }

  public validateForm(form: FormGroup): string[] {
    let messagesValidationsList: string[] = [];

    if (form.get("cai").value != "" && form.get("cai").value.length > 4) {
      messagesValidationsList.push("CAI must be 4 characters long.");
      form.get("cai").setValue(form.get("cai").value.slice(0, 4));
    }

    if (form.get("middleInitial").value.length > 1) {
      messagesValidationsList.push("Middle Initial must be 1 character long.");
      form
        .get("middleInitial")
        .setValue(form.get("middleInitial").value[0].slice(0, 1));
    }

    if (form.get("role").value === "")
      messagesValidationsList.push("Please select Role.");

    if (form.get("lastName").value === "")
      messagesValidationsList.push("Please enter Last Name.");

    if (form.get("firstName").value === "")
      messagesValidationsList.push("Please enter First Name.");

    if (form.get("email").value === "")
      messagesValidationsList.push("Please enter Email.");

    return messagesValidationsList;
  }

  public getActiveQAEs() {
    return this.http.get<IBuyerEngineerQaeDetailViewData[]>(
      `${apiUrl}/qaqcbuyerengineerqae/activeQaes`
    );
  }

  public getActiveEngineers() {
    return this.http.get<IBuyerEngineerQaeDetailViewData[]>(
      `${apiUrl}/qaqcbuyerengineerqae/activeEngineers`
    );
  }

  public getActiveBuyers() {
    return this.http.get<IBuyerEngineerQaeDetailViewData[]>(
      `${apiUrl}/qaqcbuyerengineerqae/activeBuyers`
    );
  }
}
