import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  SearchComponent,
  FooterComponent,
  TabbedComponent,
  CardSliderComponent,
  SliderComponent,
  DrawerComponent,
  NpSetLayoutDirective,
  NpSelectDirective,
  NpTableDirective,
  ValidNextPageRoutePipe,
  AlertComponent,
} from "@cvx/nextpage";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "./material.module";
import { SpinnerComponent } from "./shared/spinner/spinner.component";
import { LoaderComponent } from "./shared/loader/loader.component";
import { ValidationComponent } from "./validation/validation.component";
import { CommodityCodesSelectableTreeComponent } from "./administration/commodity-codes/commodity-codes-selectable-tree/commodity-codes-selectable-tree.component";
import { EngineeringTabComponent } from "./vendor-information/performance-tracking/tabs/engineering-tab.component";
import { ProcurementTabComponent } from "./vendor-information/performance-tracking/tabs/procurement-tab.component";
import { ManufacturingTabComponent } from "./vendor-information/performance-tracking/tabs/manufacturing-tab.component";
import { StatsTabComponent } from "./vendor-information/performance-tracking/tabs/stats-tab.component";
import { ConstructionTabComponent } from "./vendor-information/performance-tracking/tabs/construction-tab.component";
import { ActionButtonComponent } from "./shared/action-button/action-button.component";

const sharedComponents = [
  SearchComponent,
  FooterComponent,
  TabbedComponent,
  CardSliderComponent,
  SliderComponent,
  DrawerComponent,
  NpSetLayoutDirective,
  NpSelectDirective,
  NpTableDirective,
  ValidNextPageRoutePipe,
  AlertComponent,
  ActionButtonComponent,
  ValidationComponent,
  SpinnerComponent,
  LoaderComponent,
  CommodityCodesSelectableTreeComponent,
  EngineeringTabComponent,
  ProcurementTabComponent,
  ManufacturingTabComponent,
  StatsTabComponent,
  ConstructionTabComponent,
];

@NgModule({
  declarations: [sharedComponents],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
  ],
  exports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    sharedComponents,
  ],
})
export class SharedModule {}
