import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { ILookupData } from "../../interfaces/administration/lookupData";
import { CountriesAndStatesService } from "../../services/countries-states.service";
import { IVendorData } from "../../interfaces/administration/vendorData";
import { VendorDataService } from "../../services/vendor-data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-create-new-vendor",
  templateUrl: "./create-new-vendor.component.html",
  styles: [
    `
      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }

      .unchecked {
        color: red;
      }

      .mt-1 {
        margin-top: 1rem !important;
      }
    `,
  ],
})
export class CreateNewVendorComponent implements OnInit {
  vendorData: IVendorData = {} as IVendorData;
  countries$: Observable<ILookupData[]>;
  states$: Observable<ILookupData[]>;
  addingNewState: boolean = false;
  acknowledgeScreening: boolean = false;
  newState: string;
  vendorForm: FormGroup;
  validationMessages: string[] = [];
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private countriesAndStatesService: CountriesAndStatesService,
    private vendorDataService: VendorDataService
  ) {
    this.vendorForm = this.fb.group({
      vendorName: ["", Validators.required],
      isSupplier: [false],
      isManufacturer: [false],
      isRml: [false],
      streetAddress: ["", Validators.required],
      countryId: ["", Validators.required],
      stateId: [{ value: "", disabled: true }],
      newState: [""],
      countyEquivlient: [""],
      city: ["", Validators.required],
      postalCode: [""],
      url: [""],
      contactName: [""],
      emailAddress: ["", [Validators.email]],
      phoneNumber: [""],
      fax: [""],
      acknowledgeScreening: [false, Validators.requiredTrue],
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.vendorForm.patchValue({ vendorName: params["name"] || "" });
    });

    this.countries$ = this.countriesAndStatesService.getAllCountries();
  }

  get countryId() {
    return this.vendorForm.get("countryId");
  }

  onCountryChange(countryId: string): void {
    const stateControl = this.vendorForm.get("stateId");
    if (countryId) {
      this.states$ =
        this.countriesAndStatesService.getStateByCountryId(countryId);
      stateControl.enable();
      this.addingNewState = false;
    } else {
      this.states$ = null;
      stateControl.disable();
    }
  }

  addNewState(): void {
    this.addingNewState = true;
    this.vendorForm.patchValue({ stateId: null, newState: "" });
  }

  cancelNewState(): void {
    this.addingNewState = false;
    this.vendorForm.patchValue({ newState: "" });
  }

  private createNewState(newState: string, countryId: number): ILookupData {
    return {
      name: newState,
      description: "",
      parentLookupId: countryId,
    };
  }

  saveVendor(): void {
    this.isLoading = true;
    const validationMessages = this.vendorDataService.validateForm(
      this.vendorForm
    );

    if (validationMessages.length === 0) {
      if (this.addingNewState && this.vendorForm.value.newState) {
        let state = this.createNewState(
          this.vendorForm.value.newState,
          this.vendorForm.value.countryId
        );
        this.countriesAndStatesService
          .createState(state)
          .subscribe((newState) => {
            this.vendorForm.patchValue({ stateId: newState.lookupId });
            this.submitVendor();
          });
      } else {
        this.submitVendor();
      }
    } else {
      this.isLoading = false;
      this.validationMessages = validationMessages;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  private submitVendor(): void {
    if (this.vendorForm.valid) {
      if (this.vendorForm.value.stateId === "") {
        this.vendorForm.patchValue({ stateId: null });
      }
      this.vendorDataService.saveVendor(this.vendorForm.value).subscribe({
        next: (response) => {
          this.router.navigate(["/vendor-information/add-vendor"], {
            queryParams: { saved: "true" },
          });
          this.isLoading = false;
        },
        error: (error) => {
          console.error(error);
          this.isLoading = false;
        },
      });
    }
  }

  cancelRequest(): void {
    this.router.navigate(["/vendor-information"]);
  }
}
