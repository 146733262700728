import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LoggedUserService } from "../../services/common/logged-user.service";
import { WorkRequestsDataService } from "../../services/qa-management/work-requests/work-requests.service";
import { IWorkRequestData } from "../../interfaces/qa-management/work-requests/workRequestData";
import { ILoggedUserData } from "../../interfaces/common/loggedUserData";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";

@Component({
  selector: "copy-work-request-form.component",
  templateUrl: "./copy-work-request-form.component.html",
})
export class CopyWorkRequestFormComponent
  extends BaseComponent
  implements OnInit
{
  addForm: FormGroup;
  validationMessages = [];
  workRequestData: IWorkRequestData;

  constructor(
    private fb: FormBuilder,
    private loggedUserService: LoggedUserService,
    private workRequestsService: WorkRequestsDataService,
    private router: Router,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.workRequestsService.copyWorkRequestData$.subscribe((data) => {
      this.workRequestData = data;
    });

    this.addForm = this.fb.group({
      poNumber: ["", Validators.required],
    });
  }

  cancelChanges(): void {
    this.router.navigate([
      `/qa-management/work-requests/edit-work-request/${this.workRequestData.workRequestId}`,
    ]);
  }

  submitForm(): void {
    let messagesValidationsList: string[] = [];
    let formPoNumber: string = this.addForm.get("poNumber").value;

    if (formPoNumber === "")
      messagesValidationsList.push(
        "PO Number is required to copy work request."
      );

    this.validationMessages = messagesValidationsList;

    if (this.addForm.valid && this.validationMessages.length === 0) {
      this.workRequestData.po = formPoNumber;
      this.workRequestData.updatedOn = new Date();
      this.workRequestData.insertedOn = new Date();

      this.loggedUserService
        .getLoggedUserData()
        .subscribe((loggedUser: ILoggedUserData | null) => {
          if (loggedUser) {
            this.workRequestData.updatedBy = loggedUser.cai;
            this.workRequestData.insertedBy = loggedUser.cai;
            this.workRequestsService
              .copyWorkRequest(this.workRequestData)
              .subscribe({
                next: (data) => {
                  this.router.navigate([
                    `/qa-management/work-requests/edit-work-request/${data.workRequestId}`,
                  ]);
                },
                error: (error) => {
                  console.error("POST /workRequests/copy" + error);
                },
              });
          } else
            console.error(
              "Cannot fetch logged user data in copy-work-request form."
            );
        });
    }
  }
}
