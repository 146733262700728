import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Sort } from "@angular/material/sort";
import { TableSortService } from "projects/aqua3/src/app/services/table-sort.service";
import { IAq2QaqcbuyerEngineerQae } from "../../interfaces/qa-management/aq2QaqcbuyerEngineerQae";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { QaqcbuyerEngineerQaeService } from "../../services/qaqc-buyer-engineer-qae.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { BaseComponent } from "../../shared/base/base.component";
import { UserPermissionsService } from "../../services/common/user-permissions.service";

@Component({
  selector: "buyers-engineers-qaes-component",
  templateUrl: "./buyers-engineers-qaes-component.html",
  styles: [
    `
      .form-check-inline {
        display: inline-block;
        margin-right: 10px;
      }

      .form-group.row.align-items-center {
        display: flex;
        align-items: center;
      }
    `,
  ],
})
export class BuyersEngineersQaesComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  title = "Buyers, Engineers, QAEs";
  public dataSource: MatTableDataSource<IAq2QaqcbuyerEngineerQae> =
    new MatTableDataSource<IAq2QaqcbuyerEngineerQae>();
  public originalDataSource: MatTableDataSource<IAq2QaqcbuyerEngineerQae> =
    new MatTableDataSource<IAq2QaqcbuyerEngineerQae>();
  public listingsForm: FormGroup;
  totalData: number = 0;

  @ViewChild("paginator") paginator: MatPaginator;

  public displayedColumns: string[] = [
    "actions",
    "name",
    "role",
    "phoneNumber",
    "address",
    "email",
    "active",
  ];

  dataSourceLoading: boolean = true;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private service: QaqcbuyerEngineerQaeService,
    userPermissionsService: UserPermissionsService
  ) {
    super(userPermissionsService);
    this.listingsForm = this.fb.group({
      name: [""],
      role: ["all"],
      email: [""],
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    if (this.paginator) {
      this.paginator.page.subscribe((event: PageEvent) => {
        this.updateListings(event.pageIndex + 1, event.pageSize);
      });
    } else {
      console.error("Paginator is not initialized");
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.updateListings();
  }

  updateListings(pageIndex: number = 1, pageSize: number = 10) {
    this.dataSourceLoading = true;

    const formValues = this.listingsForm.value;
    const params = {
      name: formValues.name,
      role: formValues.role,
      email: formValues.email,
      pageIndex: pageIndex,
      pageSize: pageSize,
    };
    this.service
      .getQaqcbuyerEngineerQae(
        params.name,
        params.email,
        params.role,
        params.pageIndex,
        params.pageSize
      )
      .subscribe({
        next: (data) => {
          this.originalDataSource =
            new MatTableDataSource<IAq2QaqcbuyerEngineerQae>(data.items);
          this.dataSource = new MatTableDataSource<IAq2QaqcbuyerEngineerQae>(
            this.originalDataSource.data
          );

          // Delay the initialization of the paginator until the data is assigned
          setTimeout(() => {
            this.paginator.pageSize = pageSize;
            this.totalData = data.totalCount;
            this.paginator.pageIndex = pageIndex - 1;
          });
          this.dataSourceLoading = false;
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  clearFilters() {
    this.listingsForm.reset({
      name: "",
      role: "all",
      email: "",
    });
    this.updateListings();
  }

  addBuyerEngineerQae() {
    this.router.navigate(["/qa-management/buyers-engineers-qaes/create"]);
  }

  edit(data: IAq2QaqcbuyerEngineerQae) {
    this.service.setCurrentAq2QaqcbuyerEngineerQae(data);
    this.router.navigate(["/qa-management/buyers-engineers-qaes/edit"]);
  }
}
