// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small-input {
  width: 60px;
}

.table-container {  
  max-height: 400px;
  overflow-y: auto;  
}  

.mandatory{
  color: red;
}

.is-invalid {
  border: 1px solid red;
}`, "",{"version":3,"sources":["webpack://./projects/aqua3/src/app/qa-management/work-requests/add-work-request-form.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".small-input {\n  width: 60px;\n}\n\n.table-container {  \n  max-height: 400px;\n  overflow-y: auto;  \n}  \n\n.mandatory{\n  color: red;\n}\n\n.is-invalid {\n  border: 1px solid red;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
