import { Injector, NgModule } from "@angular/core";
import { HomeComponent } from "./home/home.component";
import { AppComponent } from "./app.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { FAQComponent } from "./help/faq/faq.component";
import { ContactComponent } from "./help/contact/contact.component";
import { HelpComponent } from "./help/help/help.component";
import { VendorDataService } from "./services/vendor-data.service";
import { TableSortService } from "./services/table-sort.service";
import { CountriesAndStatesService } from "./services/countries-states.service";
import { AdministrationModule } from "./administration/administration.module";
import { MenuModule } from "./menu/menu.module";
import { CustomErrorComponent } from "./shared/error-page/custom-error-page.component";
import { HttpErrorInterceptor } from "./interceptors/http-error-interceptor";
import { QaManagementModule } from "./qa-management/qa-management.module";
import { ApprovedVendorListModule } from "./approved-vendor-list/approved-vendor-list.module";
import { environment } from "../environments/environment";
import { VendorInformationModule } from "./vendor-information/vendor-information.module";
import { ReportsFormsModule } from "./reports-forms/reports-forms.module";
import { CalAngularModule } from "@cvx/cal-angular";
import { AppRoutingModule } from "./app-routing.module";
import { TokenInterceptor } from "./interceptors/token-interceptor";

const componentList = [
  AppComponent,
  FAQComponent,
  ContactComponent,
  HelpComponent,
  HomeComponent,
  CustomErrorComponent,
];

@NgModule({
  declarations: [componentList],
  imports: [
    HttpClientModule,
    AdministrationModule,
    ApprovedVendorListModule,
    VendorInformationModule,
    QaManagementModule,
    ReportsFormsModule,
    VendorInformationModule,
    MenuModule,
    AppRoutingModule,
    CalAngularModule.forRoot({
      autoSignIn: true,
      popupForLogin: false,
      cacheLocation: "localStorage",
      instance: "https://login.microsoftonline.com/",
      tenantId: "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
      clientId: environment.clientId,
      redirectUri: "/auth",
      oidcScopes: ["openid", "profile", "User.Read", "offline_access"],
      graphScopes: ["User.Read", "User.Read.All", "Group.Read.All"],
    }),
  ],
  exports: [],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    // ...(environment.production
    //   ? [
    //       {
    //         provide: HTTP_INTERCEPTORS,
    //         useClass: HttpErrorInterceptor,
    //         multi: true,
    //       },
    //     ]
    //   : []),
    VendorDataService,
    TableSortService,
    CountriesAndStatesService,
  ],
})
export class AppTest {
  constructor(private injector: Injector) {}
}
