import { Component, Input } from "@angular/core";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styles: [
    `
    `,
  ],
})
export class SpinnerComponent {
  @Input() isLoading: boolean;
  @Input() diameter: string = "20";
  @Input() spinnerColor: string = "accent";
  @Input() loadingText: string = "Loading..."
  @Input() showText: boolean = true;
  @Input() scale: number = 1;
}
