import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Sort } from "@angular/material/sort";
import { TableSortService } from "projects/aqua3/src/app/services/table-sort.service";
import { IAq2QaqcbuyerEngineerQae } from "../../interfaces/qa-management/aq2QaqcbuyerEngineerQae";
import { MatPaginator } from "@angular/material/paginator";
import { QaqcbuyerEngineerQaeService } from "../../services/qaqc-buyer-engineer-qae.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ILoggedUserData } from "../../interfaces/common/loggedUserData";
import { LoggedUserService } from "../../services/common/logged-user.service";

@Component({
  selector: "buyers-engineers-qaes-create-component",
  templateUrl: "./buyers-engineers-qaes-create-component.html",
  styles: [
    `
      .form-check-inline {
        display: inline-block;
        margin-right: 10px;
      }

      .form-group.row.align-items-center {
        display: flex;
        align-items: center;
      }

      .button-container {
        display: flex !important;
        justify-content: space-between !important;
      }
    `,
  ],
})
export class BuyersEngineersQaesCreateComponent implements OnInit {
  title = "Create buyers, engineers or QAEs";
  form: FormGroup;
  isLoading = false;
  validationMessages: string[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private service: QaqcbuyerEngineerQaeService,
    private loggedUserService: LoggedUserService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      cai: [""],
      role: ["", Validators.required],
      lastName: ["", Validators.required],
      firstName: ["", Validators.required],
      middleInitial: ["", Validators.maxLength(1)],
      address: ["", Validators.required],
      phoneNumber: [""],
      faxNumber: [""],
      email: ["", [Validators.required, Validators.email]],
      active: [false],
    });
  }

  onSubmit(): void {
    this.validationMessages = this.service.validateForm(this.form);
    if (this.validationMessages.length === 0) {
      let loggedUser: ILoggedUserData;
      this.loggedUserService.getLoggedUserData().subscribe((data) => {
        loggedUser = data;
      });
      this.isLoading = true;
      const formValue = this.form.value;
      const newBuyer: IAq2QaqcbuyerEngineerQae = {
        id: 0,
        lastName: formValue.lastName,
        firstName: formValue.firstName,
        middleInitial: formValue.middleInitial,
        cai: formValue.cai,
        address: formValue.address,
        phone: formValue.phoneNumber,
        fax: formValue.faxNumber,
        active: formValue.active,
        email: formValue.email,
        updatedBy: loggedUser.cai,
        updatedOn: new Date().toISOString(),
        isBuyer: formValue.role === "Buyer",
        isEngineer: formValue.role === "Engineer",
        isQae: formValue.role === "QAE",
        insertedBy: loggedUser.cai,
        insertedOn: new Date().toISOString(),
      };

      this.service.saveQaqcbuyerEngineerQae(newBuyer).subscribe({
        next: (response) => {
          this.isLoading = false;
          this.router.navigate(["/qa-management/buyers-engineers-qaes"]);
        },
        error: (error) => {
          this.validationMessages.push(error);
          this.isLoading = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        },
        complete: () => {},
      });
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  cancelRequest(): void {
    this.router.navigate(["/qa-management/buyers-engineers-qaes"]);
  }

  newEntry(): void {
    this.validationMessages = [];
    this.form.reset();
  }
}
